import linkedIn from "./linkedin.png";
// import twitter from "./twitter.png";
// import instagram from "./instagram.png";
// import tiktok from "./tik-tok.png";
// import youtube from "./youtube.png";
import github from "./github.png";

const socialData = [
  // { name: "Tik-Tok", link: "https://www.tiktok.com/@ucheeuboh", icon: tiktok },
  // {
  //   name: "Youtube",
  //   link: "https://www.youtube.com/channel/UC_gm8IUjrt4riVrBEDX2PKw",
  //   icon: youtube,
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/ucheeuboh",
  //   icon: instagram,
  // },
  // { name: "Twitter", link: "https://twitter.com/ucheeuboh", icon: twitter },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ucheeuboh",
    icon: linkedIn,
  },
  {
    name: "GitHub",
    link: "https://github.com/Ubsss/public",
    icon: github,
  },
];

export { socialData };
