const dogosData = {
  name: "Nala and Sarabi!",
  description: "Little Yorkies with big energy.",
  pictures: [
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos1-min.jpeg?alt=media&token=5c10aaef-bc3a-4671-a3fa-02dbc676c762",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos2-min.jpeg?alt=media&token=2d6f14b2-2057-437f-9ff0-e97cffa73315",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos3-min.jpeg?alt=media&token=015fc682-0d0b-492a-91de-aa7656326385",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos4-min.jpeg?alt=media&token=fbcd3354-de0a-4d03-b8e8-f8a705da17d2",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos5-min.jpeg?alt=media&token=408405bb-e575-481d-ab8f-02e80fd941cd",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos6-min.jpeg?alt=media&token=9a91ba91-fcd2-4064-b089-7a57f6c59311",
"https://firebasestorage.googleapis.com/v0/b/portfolio-site-8e4f6.appspot.com/o/dogos%2Fdogos7-min.jpeg?alt=media&token=eecfffd5-b9b6-4532-a5d0-6b365b3335be"
  ],
};

export default dogosData;
