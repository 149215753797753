import { useSelector } from "react-redux";
import "./dogos.css";
import dogosData from "./dogosData";

export default function Home() {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <div className="container text-center">
      <p>
        <span id={`${darkMode ? "section-title-light" : "section-title-dark"}`}>
          My dogos 
        </span>
        <br />
        <span
          id={`${
            darkMode ? "section-subtitle-light" : "section-subtitle-dark"
          }`}
        >
          Here are my little trouble makers.
        </span>
      </p>
      <div
        id={`${darkMode ? "section-text-light" : "section-text-dark"}`}
        className="row h-100 w-100"
      >
        <div className="col">
          <div id="carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              {dogosData.pictures.map((_, idx) => (
                <button
                  key={idx}
                  type="button"
                  data-bs-target="#carousel"
                  data-bs-slide-to={`${idx}`}
                  className={idx === 0 ? "active" : ""}
                  aria-current={idx === 0 ? "true" : ""}
                  aria-label={`Slide ${idx++}`}
                />
              ))}
            </div>
            <div className="carousel-inner px-2">
              {dogosData.pictures.map((img, idx) => {
                return (
                  <div
                    key={idx}
                    className={`carousel-item ${idx === 0 ? "active" : ""}`}
                  >
                    <img
                      id="dogos-image"
                      src={img}
                      className="m-2 img-fluid"
                      alt={idx}
                    />
                  </div>
                );
              })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <p>
            <strong>{dogosData.name}</strong>
          </p>
          <p>{dogosData.description}</p>
        </div>
      </div>
    </div>
  );
}
